export default [
  {
    title: <h2>How can I find a cofounder using my online profile?</h2>,
    content: (
      <>
        <p>
          To find a co founder using your online profile, treat it like you
          would your resume. That means that you need to have:
        </p>
        <ul>
          <li>
            <p>A professional profile photo to help build trust. </p>
          </li>
          <li>
            <p>
              A description that shows why you would make a good co-founder.
              This could be details of your experience, and your achievements in
              business.
            </p>
          </li>
          <li>
            <p>
              Something that makes you stand out in a co founder search.
              Remember that someone looking to find a co founder will often be
              searching through multiple profiles while they’re looking for
              business partners. Make yours the one that they remember by
              including a memorable detail about something you’ve achieved that
              will make you a fantastic co-founder.
            </p>
          </li>
          <li>
            <p>
              Include an elevator pitch that outlines your business idea. Be
              clear on:
            </p>
            <ul>
              <li>What your idea is. </li>
              <li>Who it’s for. </li>
              <li>
                Why you’re the right person to start a business in your niche.
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
    key: 1,
  },
  {
    title: (
      <h2>
        What’s the best way to start a conversation when you’re trying to find a
        cofounder?
      </h2>
    ),
    content: (
      <>
        <p>
          To open a conversation when you’re looking to find business partners
          online, treat it as if you were on a dating site. Rather than sending
          a generic message, take the time to personalise to maximise the
          chances that you’ll find a founder.
        </p>
        <p>
          Research each potential co-founder that you decide to approach during
          your business partner search, so that you can reference their profile
          and business idea when you message. Tell them why you decided to
          approach them and what it is about their profile that makes you think
          you’ll work well together.{" "}
        </p>
      </>
    ),
    key: 2,
  },
  {
    title: (
      <h2>How long does it take for co-founders to reply to a message?</h2>
    ),
    content: (
      <>
        <p>
          Remember that most people in business are busy, so it can take a few
          days for someone to reply. They might have a set time in their diary
          each week to respond to messages, so be patient.
        </p>
        <p>
          Our system will automatically send reminder emails for any unread
          messages: the first reminder will be sent after 3 days, and the second
          after 7 days. The profiles that are shown first in a search are the
          most active profiles – we make an automatic calculation based on how
          recently the member has accessed the site and when they last read
          their messages.
        </p>
      </>
    ),
    key: 3,
  },
  {
    title: (
      <h2>What happens after I’ve started a conversation on Starthawk?</h2>
    ),
    content: (
      <>
        <p>
          Once you’ve exchanged messages, it’s time to have a real-time
          conversation. We recommend using video calling software such as Zoom,
          Skype or Whatsapp when meeting cofounders.
        </p>
        <p>
          If your potential co-founder is local to you, you could decide to meet
          in person. If you do this, we recommend you always choose a busy,
          public place such as a bar or cafe.
        </p>
      </>
    ),
    key: 4,
  },
  {
    title: <h2>What are the differences between free and paid membership?</h2>,
    content: (
      <>
        <p>
          Paid membership includes all the benefits of free membership, plus:
        </p>
        <ul>
          <li>
            Unlimited messaging. You can start as many conversations as you
            like.
          </li>
          <li>Featured profile. Get seen at the top of more searches.</li>
          <li>Premium filters to help you narrow your search. </li>
          <li>
            Read receipts, so you can see when your message has been read.
          </li>
          <li>
            Activity status, so you can see how active someone is on the site
            before you message.
          </li>
          <li>
            Discounts with our partner apps to help you start and grow your
            business.
          </li>
          <li>
            Cancel anytime. Change or cancel your plan any time you choose.
          </li>
          <li>
            Premium badge. Stand out from the crowd as a serious prospect with
            our premium badge.
          </li>
        </ul>
      </>
    ),
    key: 5,
  },
  {
    title: (
      <h2>
        Should I be worried that someone will steal my idea? Do I need to use an
        NDA?
      </h2>
    ),
    content: (
      <>
        <p>
          Most co-founders will want to meet you to discuss your idea in good
          faith. However, looking at looking for a business partner, it’s
          important to carry out online research before you meet, such as
          looking at their LinkedIn profile, so that you know they are who they
          say they are.
        </p>
        <p>
          You can ask them to sign an NDA before you meet if you are concerned,
          but some co-founders may prefer to have an informal discussion first.
        </p>
        <p>
          It’s also worth noting that your initial idea is not a business. If
          you’re wondering how to find a cofounder, you need to focus as much on
          execution as finding the right person. Whether your idea becomes a
          successful business or not depends on how you work together to start
          and scale it.
        </p>
      </>
    ),
    key: 6,
  },
];
