import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import classNames from 'classnames';

import Cta from '../cta/cta';

import {
  LOCAL_TOGGLE_PREMIUM_COFOUNDER
} from '../../apollo-state/graphql/mutations';

import './premium-badge.scss';

class PremiumBadge extends PureComponent {
  render() {
    const { copy, showUpgradeHoverMessage, popupIsBelow } = this.props;

    return (
      <div className={classNames("premium-badge", {
        "premium-badge--popup-below": popupIsBelow
      })}>
        {copy}
        {showUpgradeHoverMessage && (
          <div className="premium-badge__popup">
            <p>Looks like you're not a pro member yet, click below to upgrade your account!</p>
            <Mutation mutation={LOCAL_TOGGLE_PREMIUM_COFOUNDER} variables={{ message: null }}>
              {togglePremiumCofounder => (
                <Cta modifierClass="premium" text="Upgrade" onClick={togglePremiumCofounder} type="button" tag="button" />
              )}
            </Mutation>
            <span className="premium-badge__popup__bridge" />
          </div>
        )}
      </div>
    );
  }
}

export default PremiumBadge;