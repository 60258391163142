import React, { PureComponent } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

import BlogTile from "./blog-tile";
import Heading from "../heading/heading";
import Cta from "../cta/cta";

import "./blog-articles.scss";

const BLOG_ARTICLES_QUERY = gql`
  query BLOG_ARTICLES_QUERY($skip: Int = 0, $first: Int) {
    blogArticles(first: $first, skip: $skip, orderBy: "createdAt_DESC") {
      id
      date
      title
      previewPicUrl
      previewPicAlt
      slug
    }
  }
`;

class BlogArticles extends PureComponent {
  render() {
    const { variables, title, showViewMore } = this.props;

    return (
      <div className="blog-articles">
        {title && (
          <div className="blog-articles__title">
            <Heading copy={title} element="h2" />
          </div>
        )}
        <div className="blog-articles__tiles">
          <Query query={BLOG_ARTICLES_QUERY} variables={variables}>
            {({ data, loading, error }) => {
              if (loading) return <p>loading...</p>;
              return data.blogArticles.map((article) => (
                <BlogTile key={article.id} {...article} />
              ));
            }}
          </Query>
        </div>
        <div className="blog-articles__show-more">
          {showViewMore && (
            <Cta text="See more like this" tag="link" link={`/blog`} />
          )}
        </div>
      </div>
    );
  }
}

export default BlogArticles;
