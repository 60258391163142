import React, { PureComponent } from "react";
import ReactDOMServer from "react-dom/server";
import Heading from "../heading/heading";
import FaqItem from "./faq-item";

import "./faq.scss";

class Faq extends PureComponent {
  render() {
    const { faqContent, title } = this.props;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      name: "StartHawk FAQ",
      mainEntity: faqContent.map((faq) => ({
        "@type": "Question",
        name: ReactDOMServer.renderToStaticMarkup(faq.title)
          .replace("<h2>", "")
          .replace("</h2>", ""),
        acceptedAnswer: {
          "@type": "Answer",
          text: ReactDOMServer.renderToStaticMarkup(faq.content),
        },
      })),
    };

    return (
      <section className="faq">
        <div className="faq__inner">
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
          />
          <div className="page__grid">
            <div className="faq__title">
              <Heading copy={title} element="h2" />
            </div>
            <ul className="faq__questions-cont">
              {faqContent.map((item) => (
                <FaqItem
                  key={item.key}
                  content={item.content}
                  title={item.title}
                />
              ))}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
