import React, { PureComponent } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import classNames from 'classnames';

const REPORT_USER_MUTATION = gql`
  mutation REPORT_USER_MUTATION(
    $userId: ID!
    $reportedFrom: String!
    $relevantItemId: ID
  ) {
    reportUser(
      userId: $userId
      reportedFrom: $reportedFrom
      relevantItemId: $relevantItemId
    ) {
      success
    }
  }
`;

class ReportUserButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      copy: props.copy
    };
  }
  setUserReported = () => {
    this.setState({
      copy: "Reported!"
    });
  }
  render() {
    const { copy } = this.state;
    const { userId, reportedFrom, relevantItemId } = this.props;

    return (
      <Mutation
        mutation={REPORT_USER_MUTATION}
        variables={{
          userId,
          reportedFrom,
          relevantItemId
        }}
        onCompleted={this.setUserReported}
      > 
        {(submit, { error, loading }) => { 
          return <button style={{ cursor: "pointer" }} onClick={submit} disabled={loading} className={classNames('report-user-button', {
            'report-user-button--loading': loading
          })}>
            {loading ? '...' : copy}
          </button>
        }}
      </Mutation>
    );
  }
}

ReportUserButton.defaultProps = {
  relevantItemId: null
};

export default ReportUserButton;