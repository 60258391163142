import React, { PureComponent } from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import classNames from 'classnames';

import './mini-menu.scss';

class MiniMenu extends PureComponent {
  constructor() {
    super();

    this.state = {
      open: false
    }
  }
  handleClickOutside = e => {
    if (this.state.open) {
      this.setState({
        open: false
      });
    }
  };
  toggleMenu = () => {
    this.setState({
      open: !this.state.open
    });
  }
  render() {
    const { open } = this.state;
    const { modifierClass, minWidth } = this.props;

    return (
      <div className={classNames("mini-menu", modifierClass, {
        "mini-menu--open": open
      })}>
        <button title="Options" className="mini-menu__button" onClick={this.toggleMenu} />
        {open && (
          <div className="mini-menu__list" style={{ minWidth }}>
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

MiniMenu.defaultProps = {
  minWidth: "200px"
}

export default enhanceWithClickOutside(MiniMenu);