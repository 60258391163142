import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './tag.scss';

class Tag extends PureComponent {
  removeTag = () => {
    this.props.removeTag(this.props.label);
  }
  render() {
    const { label, hasRemoveFunction, hasHashTag } = this.props;

    return (
      <div className="tag">
        <div className="tag__inner">
          {hasHashTag ? `# ${label}` : label}
          {hasRemoveFunction && (
            <button type="button" className="tag__remove" onClick={this.removeTag}>remove</button>
          )}
        </div>
      </div>
    );
  }
}

Tag.propTypes = {

};

export default Tag;