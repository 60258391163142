import React, { PureComponent } from "react";
import Link from "next/link";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
dayjs.extend(advancedFormat);
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./blog-tile.scss";

class BlogTile extends PureComponent {
  render() {
    const {
      title,
      date,
      previewPicUrl,
      previewPicAlt,
      slug,
      referencePageNumber,
    } = this.props;

    return (
      <ReactCSSTransitionGroup
        transitionName="overlay"
        transitionAppear={true}
        component={React.Fragment}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <article className="blog-tile">
          <Link
            href={`/blog/post/[slug]${
              process.browser && referencePageNumber
                ? "?pageidxref=" + referencePageNumber
                : ""
            }`}
            as={`/blog/post/${slug}${
              process.browser && referencePageNumber
                ? "?pageidxref=" + referencePageNumber
                : ""
            }`}
          >
            <a>
              <div className="blog-tile__inner">
                <div>
                  <div className="blog-tile__img-cont">
                    <LazyLoadImage
                      alt={previewPicAlt}
                      height={"100%"}
                      src={previewPicUrl}
                      effect="opacity"
                      width={"100%"}
                      placeholderSrc={previewPicUrl}
                    />
                  </div>
                </div>
                <div className="blog-tile__content">
                  <h2>{title}</h2>
                  <p className="blog-tile__time-stamp">
                    {dayjs(date).format("Do MMMM YYYY")}
                  </p>
                </div>
              </div>
            </a>
          </Link>
        </article>
      </ReactCSSTransitionGroup>
    );
  }
}

export default BlogTile;
