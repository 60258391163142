function getDateString(dateParam, withSlashes) {
  let date;
  if (!dateParam) {
    date = new Date();
  } else {
    date = new Date(dateParam);
  }
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  const yyyy = date.getFullYear();
  return withSlashes ? `${dd}/${mm}/${yyyy}` : `${dd}${mm}${yyyy}`;  
}

export default getDateString;