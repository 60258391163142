import React, { PureComponent } from "react";
import classNames from "classnames";
import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import Link from "next/link";

import customRendererLink from "../../utils/custom-link-renderer";
import getDateString from "../../utils/date";
import convertProfilePicUrl from "../../utils/convert-profile-pic-url";

class Message extends PureComponent {
  render() {
    const {
      me,
      createdBy: { profilePic, firstName, id },
      text,
      createdAt,
      todaysDate,
    } = this.props;

    const isToday = getDateString(createdAt) === todaysDate;

    return (
      <div
        className={classNames("conversation__message", {
          "conversation__message--other-user": me.id !== id,
        })}
        key={id}
      >
        <div className="conversation__message__inner">
          <div className="conversation__message__text">
            <div className="conversation__message__details">
              <div className="conversation__message__profile-pic">
                <img
                  src={convertProfilePicUrl(profilePic, 18, 18)}
                  alt={firstName}
                />
              </div>
              <div className="conversation__message__meta">
                <div className="conversation__message__name">
                  {me.id !== id ? (
                    <Link href="/profile/[id]" as={`/profile/${id}`}>
                      <a className="user-tile__first-name__link">{firstName}</a>
                    </Link>
                  ) : (
                    "You"
                  )}
                  &nbsp;
                </div>
                <div className="conversation__message__time">
                  &nbsp;
                  {isToday && "Today "}
                  {dayjs(createdAt).format(
                    isToday ? "HH:mm" : "DD/MM/YYYY HH:mm"
                  )}
                </div>
              </div>
            </div>
            <div className="conversation__message__text__inner">
              <ReactMarkdown
                source={text}
                escapeHtml={false}
                renderers={customRendererLink}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Message.propTypes = {};

export default Message;
