import React, { PureComponent } from "react";
import { Mutation } from "react-apollo";
import BlogArticles from "../components/blog-articles/blog-articles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Image from "next/image";
import Link from "next/link";

import Cta from "../components/cta/cta";
import Heading from "../components/heading/heading";
import Head from "../components/head-data/head-data";
import UserTile from "../components/user-tile/user-tile";
import Message from "../containers/chat-overlay/message";
import Faq from "../components/faq/faq";
import faqContent from "../data/faq-content-hp";

import { data } from "../data/home-dummy-data.json";

import { LOCAL_TOGGLE_OVERLAY_MUTATION } from "../apollo-state/graphql/mutations";

import "./homepage.scss";

class Home extends PureComponent {
  render() {
    const { me } = this.props;

    return (
      <Mutation mutation={LOCAL_TOGGLE_OVERLAY_MUTATION}>
        {(toggleOverlay) => (
          <div className="index">
            <Head
              title="Find Business Partners Online | Meeting Co-founders"
              description="The perfect place to find a co-founder that matches your skillset. Get started for free today and get your amazing idea off the ground!"
              url="https://www.starthawk.io"
            />
            <div className="home">
              <section className="home-fold">
                <div className="home-fold__content">
                  <div className="home-fold__content__left">
                    <h1>
                      <span>Find a co-founder</span>
                    </h1>
                    <p>
                      Finding a co-founder is hard, we provide you with the
                      platform to make that easier. Get started for free today
                      and find the missing piece of your startup journey through
                      a co-founder.
                    </p>
                    {!me && (
                      <div className="home-fold__content__cta-cont">
                        <Cta
                          modifierClass="white-outline"
                          text="Find me a co-founder"
                          onClick={toggleOverlay}
                          type="submit"
                          tag="button"
                          args={{ type: "signup" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="home-fold__content__right">
                    <Image
                      src="/static/images/find-a-cofounder-hero-with-bg.svg"
                      alt="Find a co-founder today"
                      height={600}
                      width={600}
                      priority={true}
                    />
                  </div>
                  <div className="home-fold__bg" />
                </div>
              </section>

              <section className="home-the-app">
                <div className="home-the-app__grid">
                  <div className="home-the-app__left">
                    <h2>
                      StartHawk's platform is here to make your co-founder
                      search as easy and effective as possible.
                    </h2>
                    <p>
                      We've built our platform and search algorithim to make
                      finding a match as easy as possible. Our intuitive
                      messaging system provides quick and easy communication
                      amongst other co-founders and our filters allow you to
                      easily narrow down the type of co-founder you're after.
                    </p>
                    <Cta
                      text="Show me"
                      tag="link"
                      link={`/find-a-co-founder`}
                    />
                  </div>
                  <div className="home-the-app__right">
                    <div className="home-the-app__right__user-tiles">
                      {data.users.slice(0, 2).map((user) => {
                        return (
                          <div
                            key={user.id}
                            className="home-the-app__right__user-tile"
                          >
                            <UserTile {...user} />
                          </div>
                        );
                      })}
                    </div>
                    <div className="home-the-app__right__messages-cont">
                      <div className="home-the-app__right__user-messages">
                        <div className="home-the-app__right__user-messages__heads">
                          {data.users
                            .filter((u) => u.id !== "2")
                            .map((user, idx) => (
                              <div
                                key={user.id}
                                className="home-the-app__right__user-messages__head"
                              >
                                {(idx === 1 || idx === 4) && (
                                  <span className="home-the-app__right__user-messages__head__noti">
                                    {idx}
                                  </span>
                                )}
                                <div
                                  key={user.id}
                                  className="home-the-app__right__user-messages__head__inner"
                                >
                                  <LazyLoadImage
                                    alt={user.firstName}
                                    height={"100%"}
                                    src={user.profilePic}
                                    effect="opacity"
                                    width={"100%"}
                                    placeholderSrc={user.profilePic}
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="home-the-app__right__user-messages__messages">
                          {data.messages.map((msg) => (
                            <div
                              key={msg.id}
                              className="home-the-app__right__user-messages__message"
                            >
                              <Message
                                {...msg}
                                me={{
                                  id: "2",
                                }}
                              />
                            </div>
                          ))}
                        </div>
                        <div className="home-the-app__right__user-messages__send">
                          <input placeholder="Type your message here..." />
                          <Cta text="send" tag="button" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="home-why-cofound">
                <img
                  className="home-why-cofound__diag"
                  src="/static/images/white-diagonal.svg"
                  alt="Starthawk UI"
                />
                <div className="home-why-cofound__grid">
                  <div className="home-why-cofound__header">
                    <Heading
                      modifierClass="why-cofound"
                      copy="Why partner with a co-founder?"
                      element="h2"
                    />
                    <p>Here's just a few good reasons:</p>
                  </div>
                  <div className="home-why-cofound__icons">
                    <ul>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"Mitigate risk for investors"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/risk-mitigation.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/risk-mitigation.svg"
                            }
                          />
                          <h3>Mitigate risk for investors</h3>
                          <p>
                            Having a company and investment rely on a single
                            founder flags numerous potential issues for
                            investors. Since there is no co-founder to fall back
                            on if something happens to the individual, it
                            hightens the risk of their investment.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"Strategic soundboarding"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/strategic-soundboarding.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/strategic-soundboarding.svg"
                            }
                          />
                          <h3>Strategic soundboarding</h3>
                          <p>
                            Making big decisions when starting and running a
                            company can have a huge impact on the growth of a
                            business. This impact can either help or hinder the
                            business hence why having someone to bounce ideas
                            off of can help give you confidence in your
                            decisions or surface flaws you may not have thought
                            of.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"Division of responsibility"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/division-of-responsibility.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/division-of-responsibility.svg"
                            }
                          />
                          <h3>Division of responsibility</h3>
                          <p>
                            The day to day responsibility of running a business
                            is often taken lightly until it comes to actually
                            doing it. Having a cofounder focus on or help you
                            with a particular part of the business will help
                            alleviate that stress, especially when the company
                            is still small and resources are stretched.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"complementary skillsets"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/complimentary-skills.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/complimentary-skills.svg"
                            }
                          />
                          <h3>Complementary skillsets</h3>

                          <p>
                            Arguably one of the most important factors when{" "}
                            <Link href={`/find-a-co-founder`}>
                              <a>looking for a co-founder</a>
                            </Link>{" "}
                            is what skills they will bring to the table. Having
                            someone come onboard and provide the missing piece
                            of the puzzle for launching or running your business
                            effectively will feel like wind in your sails.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"Faster time to launch"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/faster-time-to-market.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/faster-time-to-market.svg"
                            }
                          />
                          <h3>Faster time to launch</h3>
                          <p>
                            Starting a business generally involves a lot of
                            blood, sweat and tears from its' founders -
                            particularly so if the business is bootstrapped.
                            Having double the dedicated labour in the form of a
                            co-founder will help you get to MVP quicker.
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="home-why-cofound__icons__item">
                          <LazyLoadImage
                            alt={"Split early out of pocket expenses"}
                            height={"100%"}
                            src={
                              "/static/images/why-co-founder-icons/split-out-of-pocket-expenses.svg"
                            }
                            effect="opacity"
                            width={"100%"}
                            placeholderSrc={
                              "/static/images/why-co-founder-icons/split-out-of-pocket-expenses.svg"
                            }
                          />
                          <h3>Split early out of pocket expenses</h3>
                          <p>
                            Starting a business can be expensive. The
                            opportunity to work with a co-founder will allow you
                            to split the initial costs of getting a working
                            product or prototype going, which will in turn allow
                            you to raise funds at a higher valuation.
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <img
                  className="home-why-cofound__diag home-why-cofound__diag--bottom"
                  src="/static/images/white-diagonal.svg"
                  alt="Starthawk UI"
                />
              </section>

              <section className="home-testi">
                <div className="pattern" />
                <div className="home-testi__grid">
                  <div className="home-testi__top">
                    <Heading
                      copy="See what our users have to say:"
                      element="h2"
                    />
                  </div>
                  <div className="home-testi__bottom">
                    <div className="home-testi__customer-grid">
                      <div className="home-testi__customer">
                        <div className="home-testi__customer__inner">
                          <div className="home-testi__customer__profile-pic">
                            <LazyLoadImage
                              alt={"user-review-1"}
                              height={"100%"}
                              src={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-1.jpg"
                              }
                              effect="opacity"
                              width={"100%"}
                              placeholderSrc={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-1.jpg"
                              }
                            />
                          </div>
                          <div className="home-testi__customer__quote">
                            I knew I wanted to start something of my own but
                            wasn't sure what. Met a tonne of great people on the
                            platform with great ideas and have decided to embark
                            on a new venture with one of them! Would highly
                            recommend to anyone in the same position!
                          </div>
                          <div className="home-testi__customer__name">
                            <strong>Pablo - Marketer</strong>
                          </div>
                        </div>
                      </div>
                      <div className="home-testi__customer">
                        <div className="home-testi__customer__inner">
                          <div className="home-testi__customer__profile-pic">
                            <LazyLoadImage
                              alt={"user-review-2"}
                              height={"100%"}
                              src={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-2.jpg"
                              }
                              effect="opacity"
                              width={"100%"}
                              placeholderSrc={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-2.jpg"
                              }
                            />
                          </div>
                          <div className="home-testi__customer__quote">
                            What I lacked the most in business was sales and
                            business development skills. Through StartHawk I
                            managed to find a co-founder with a wealth of
                            experience in SAAS sales which meant I could focus
                            on what I do best - product development
                          </div>
                          <div className="home-testi__customer__name">
                            <strong>Eric - Developer</strong>
                          </div>
                        </div>
                      </div>
                      <div className="home-testi__customer">
                        <div className="home-testi__customer__inner">
                          <div className="home-testi__customer__profile-pic">
                            <LazyLoadImage
                              alt={"user-review-3"}
                              height={"100%"}
                              src={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-3.jpg"
                              }
                              effect="opacity"
                              width={"100%"}
                              placeholderSrc={
                                "https://res.cloudinary.com/djrpmnbbi/image/upload/w_116,h_116,f_auto,q_auto/v1581435819/website-assets/homepage/user-review-3.jpg"
                              }
                            />
                          </div>
                          <div className="home-testi__customer__quote">
                            Was delighted to find my co-founder on StartHawk -
                            its made the whole process of getting external
                            investment a lot easier along with getting my
                            product to MVP sooner than expected
                          </div>
                          <div className="home-testi__customer__name">
                            <strong>Anita - Sales</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    {!me && (
                      <div className="home-testi__cta-cont">
                        <Cta
                          bgColor="white"
                          text="Join the community"
                          onClick={toggleOverlay}
                          type="submit"
                          tag="button"
                          args={{ type: "signup" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </section>
              <section className="home-blog">
                <img
                  className="home-blog__diag"
                  src="/static/images/grey-diagonal.svg"
                  alt="Starthawk UI"
                />
                <div className="page__grid">
                  <BlogArticles
                    variables={{ skip: 0, first: 3 }}
                    title="Latest from our blog:"
                    showViewMore
                  />
                </div>
              </section>
              <Faq faqContent={faqContent} title="FAQ" />
            </div>
          </div>
        )}
      </Mutation>
    );
  }
}

export default Home;
