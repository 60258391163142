import React, { PureComponent } from "react";
import className from "classnames";

class FaqItem extends PureComponent {
  constructor() {
    super();
    this.state = {
      open: false,
      height: 0,
    };
  }
  toggleExpand = () => {
    if (!this.state.open) {
      this.setState({
        height: "auto",
        open: true,
      });
    } else {
      this.setState({
        height: 0,
        open: false,
      });
    }
  };

  render() {
    const { content, title } = this.props;
    const { height, open } = this.state;

    return (
      <li
        className={className("faq__item", {
          "faq__item--open": open,
        })}
      >
        <button onClick={this.toggleExpand}>{title}</button>
        <div
          style={{ height }}
          className="faq__item__content"
          ref={this.containerRef}
        >
          <div className="faq__item__content__inner">{content}</div>
        </div>
      </li>
    );
  }
}

export default FaqItem;
