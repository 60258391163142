import React, { PureComponent } from 'react';
import { string } from 'prop-types';
import className from 'classnames';

import './heading.scss';

class Heading extends PureComponent {
  render() {
    const { element, copy, noUnderline, modifierClass } = this.props;
    return (
      <div className={className("heading", {
        'heading--no-underline': noUnderline,
        [`heading--${modifierClass}`]: modifierClass
      })}>
        {element === 'h1' && <h1>{copy}</h1>}
        {element === 'h2' && <h2>{copy}</h2>}
        {element === 'h3' && <h3>{copy}</h3>}
      </div>
    );
  }
}

Heading.propTypes = {
  element: string.isRequired
};

export default Heading;
